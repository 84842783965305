import gql from "graphql-tag";
import { apiConfig } from "./apiConfig";
import { handleResponse, handleError } from "./apiHelper";
import moment from "moment";

export const reportApi = {
    getAccountingReport,
    getBookingBalanceReport,
    getOccupancyReport,
    getSalesReport,
    checkInCheckoutReportByDate,
    bookingsByDate,
    ordersByDate,
    paymentsByDate,
    refundsByDate,
    getBookingsOrder,
    getBookingsRefund,
    getBookingsPayment,
    getPaymentByDate,
    getPaymentType,
    getRefundByDate,
    getFinancialReport,
    generateOccupancyReport

};


function getAccountingReport(tenant_id, checkOutDate, checkInDate) {
    return gqlPostQueryResult(`query {
  get_accounting_report(args: {p_tenant_id: "${tenant_id}", p_end_date: "${checkOutDate}", p_tart_data:"${checkInDate}"}, order_by: {total_cash_amount: asc}) {
    total_cash_amount
    total_credit_card_amount
    total_refund_amount
    total_room_revenue
    total_service_charge
    total_service_revenue
  }
}`);
}

function getSalesReport(tenant_id, checkOutDate, checkInDate) {
    return gqlPostQueryResult(`query {
  get_sales_report(args: {p_tenant_id: "${tenant_id}", end_date: "${checkOutDate}", start_data: "${checkInDate}"}) {
    booking_source,
    no_of_booking,
    total_income,
    paid
  }
}`);
}

function getBookingBalanceReport(tenant_id) {
    return gqlPostQueryResult(`query {
      get_booking_balance_report(args: {p_tenant_id: "${tenant_id}"}) {
        booking_no,
        guest_name,
        check_in_date,
        check_out_date,
        charge_amount,
        paid_amount,
        remaining_amount,
        id
      }
    }`);
}

function getOccupancyReport(tenant_id, checkOutDate, checkInDate) {
    return gqlPostQueryResult(`query {
    get_occupancy_report(args: {p_end: "${checkOutDate}", p_start: "${checkInDate}", p_tenant_id: "${tenant_id}"}, order_by: {a_date: asc}) {
       occupied_rooms_qty,
       room_qty,
       room_type
       a_date,
      }
    }`);
}

function gqlPostQueryResult(_query) {
    const gql = { query: _query };

    const url = apiConfig.hasuraUrl;
    const requestOptions = {
        method: "POST",
        headers: {
            "x-hasura-admin-secret": apiConfig.hasuraSecret,
            // 'Authorization': `Bearer ${apiConfig.getRideumToken()}`,
            "Content-Type": "application/json-patch+json",
        },
        body: JSON.stringify(gql),
    };

    return fetch(url, requestOptions)
        .then(handleResponse, handleError)
        .then((data) => {
            return data;
        });
}

function bookingsByDate(date, tenantId) {
let _date =  moment(date).format('YYYY-MM-DD')
    const gql = `query{
        bookings(where:{
            status: {_neq: Cancelled},
                tenant_id: {_eq: "${tenantId}"},
                check_in_date: {
                    _lte: "${_date}"
                },
                check_out_date:{
                    _gt: "${_date}"
                }
        }){
            id
            check_in_date
            check_out_date
            booking_no
            customer_type
            guest{
                first_name
                last_name
            }
            company {
                name
            }
        }
    }`

    return gqlPostQueryResult(gql)
}

function checkInCheckoutReportByDate(tenant_id, date) {
    const gql = `query {
          accommodations(where:{tenant_id:{_eq: "${tenant_id}"},status:{_nin: [Cancelled,New]},_or:[{check_in_date: {_eq: "${date.toISOString().substring(0, 10)}"}}{check_out_date: {_eq: "${date.toISOString().substring(0, 10)}"}}]}){
              status
              check_in_date
              check_out_date
              room{
                  name
              }
              booking{
                  customer_type
                  guest{
                      first_name
                      last_name
                  }
                  company{
                      name
                  }
              }
          }
      }`

    return gqlPostQueryResult(gql);
}


function getBookingsPayment(bookingIds) {
    const gql = `query {
      payments(where:{booking_id:{_in:${JSON.stringify(bookingIds)}}, status: {_eq: Confirmed}, payment_type: {_neq: Deposit}}){
          amount
          booking_id
      }
  }`
    return gqlPostQueryResult(gql)
}

function getBookingsOrder(bookingIds) {
    const gql = `query {
      orders(where:{booking_id:{_in:${JSON.stringify(bookingIds)}}, status:{_neq: Cancelled}}){
          booking_id
          total_price
      }
  }`
    return gqlPostQueryResult(gql)
}

function getBookingsRefund(bookingIds) {
    const gql = `query{
      refunds(where:{booking_id: {_in: ${JSON.stringify(bookingIds)}},status:{_neq: Cancelled}, _not:{deposit:{}}}){
          amount
          booking_id
      }
  }`
    return gqlPostQueryResult(gql)
}

function paymentsByDate(date, tenantId) {
    const gql = `query{
      payments(where:{tenant_id:{_eq: "${tenantId}"},
              status:{_eq:Confirmed},
              payment_type:{_neq: Deposit},
              booking:{
              check_in_date:{_lte:"${date.toISOString().substring(0, 10)}"},
              check_out_date: {_gt: "${date.toISOString().substring(0, 10)}"}
      }}){
          booking{
              booking_no
          }
          amount
          status
      }
  }`

    return gqlPostQueryResult(gql)


}


function ordersByDate(date, tenantId) {
    const gql = `query{
      orders(where:{tenant_id: {_eq: "${tenantId}"}, status: {_neq: Cancelled},
              accommodation:{
                  booking:{check_in_date:{_lte: "${date.toISOString().substring(0, 10)}"}, check_out_date:{_gt:"${date.toISOString().substring(0, 10)}"}}
              }
      }){
          total_price
          accommodation{
          booking{
              check_in_date
              check_out_date
              booking_no
              customer_type
              guest{
                  first_name
                  last_name
              }
              company{
                  name
              }
          }
          }
      }
  }`
    return gqlPostQueryResult(gql)
}

function refundsByDate(date, tenantId) {
    const gql = `query{
      refunds(where:{tenant_id:{_eq:"${tenantId}"}, status:{_neq:Cancelled}, order:{accommodation:{booking:{
          check_in_date:{_lte:"${date.toISOString().substring(0, 10)}"},
         check_out_date:{_gt:"${date.toISOString().substring(0, 10)}"}
      }}}}){
          amount
          order{
              accommodation {
                  booking{
                      booking_no
                      check_in_date
                      check_out_date
                  }
              }
          }

      }
  }`
    return gqlPostQueryResult(gql)
}

function getPaymentByDate(tenant_id, start_date, end_date) {
    const gql = `query {
        payments(where: {tenant_id: {_eq: "${tenant_id}"}, status: {_eq: "Confirmed"}, created_at: {_gte: "${start_date}", _lte: "${end_date}"}}) {
            amount
            payment_type
          }
        }`

    return gqlPostQueryResult(gql);
}

function getPaymentType() {
    const gql = `query {
                e_payment_types {
                  id
                  comment
                }
              }
            `
    return gqlPostQueryResult(gql);
}

function getRefundByDate(tenant_id, start_date, end_date) {
    const gql = `query {
        refunds(where: {tenant_id: {_eq: "${tenant_id}"}, status: {_nin: Cancelled}, created_at: {_gte: "${start_date}", _lte: "${end_date}"}}) {
               amount
               type
               deposit_id
          }
        }`

    return gqlPostQueryResult(gql);
}

async function getFinancialReport(fromDate, toDate, tenantId) {
    const orderQuery = `query{
    orders(where:{status:{_neq:Cancelled}, tenant_id:{_eq:"${tenantId}"}, created_at:{_gte:"${fromDate.format("YYYY-MM-DD")}", _lt:"${toDate.format("YYYY-MM-DDTT23:59:59")}"}}){
        id
        booking_id
        order_no
        order_type
        total_price
        status
        invoice_id
        service{
            name
            price
        }
        accommodation{
            room_type{
                name
            }
        }
    }
  }`
    const orders = (await gqlPostQueryResult(orderQuery)).data.orders
    const invoiceQuery = `query {
        invoices(where:{status: {_neq:Cancelled},tenant_id:{_eq: "${tenantId}"}, invoice_items:{order_id:{_in:${JSON.stringify(orders.map(order => order.id))}}}}){
           invoice_items{
                order_id
           }
        }
    }`

    const invoices = (await gqlPostQueryResult(invoiceQuery)).data.invoices
    const invoicedOrderIds = invoices.flatMap(invoice => invoice.invoice_items).map(ii => ii.order_id)
    const roomTypeQuery = `query{
      room_types(where:{tenant_id:{_eq:"${tenantId}"}, status:{_eq:Active}}){
          name
      }
  }`

    const roomTypes = (await gqlPostQueryResult(roomTypeQuery)).data.room_types.map(room_type => room_type.name)
    const report = roomTypes.map(roomType => {
        const allOrders = orders.filter(({ order_type, accommodation }) => order_type === "Accommodation" && accommodation.room_type.name == roomType)
        const grossTotal = allOrders.map(order => order.total_price).reduce((acc, curr) => acc + curr, 0)
        const invoicedTotal = allOrders.filter(order => invoicedOrderIds.includes(order.id)).map(order => order.total_price).reduce((acc, curr) => acc + curr, 0)
        return { room_type: roomType, booked_gross_total: grossTotal, invoiced_gross_total: invoicedTotal }
    })

    const serviceQuery = `query{
      services(where:{tenant_id:{_eq:"${tenantId}"}}){
          name
      }
  }`
    const allServices = (await gqlPostQueryResult(serviceQuery)).data.services.map(service => service.name)
    const serviceReport = allServices.map(service => {
        const allOrders = orders.filter((order) => order.order_type == 'Service' && service == order.service.name)
        const bookedTotal = allOrders.map(order => order.total_price).reduce((acc, curr) => acc + curr, 0)
        const invoicedTotal = allOrders.filter(order => invoicedOrderIds.includes(order.id)).map(order => order.total_price).reduce((acc, curr) => acc + curr, 0)
        return { service, bookedTotal, invoicedTotal }
    })
    return {
        rooms: report,
        services: serviceReport
    }
}

/***
    * @param {import("moment").Moment} fromDate
    * @param {import("moment").Moment} toDate
    * @param {string} tenantId
    * */
async function generateOccupancyReport(fromDate, toDate, tenantId) {
    const roomQuery = `query{
        rooms(where:{tenant_id:{_eq:"${tenantId}"},status:{_eq: Active}}){
            status
            id
            name
            room_type{
            name
            }
        }
    }`
    const roomTypeQuery = `query{
        room_types(where:{ tenant_id:{_eq:"${tenantId}"}}){
            name
        }
    }`

    const accommodationQuery = `query{
        accommodations(where:{tenant_id:{_eq:"${tenantId}"}, status:{_neq:Cancelled}, check_in_date:{_gte:"${fromDate.format("YYYY-MM-DD")}", _lte:"${toDate.format("YYYY-MM-DD")}"}}){
            status
            check_in_date
            check_out_date
            room_type{
                name
            }
        }
    }`

    const maintenanceQuery = `query{
        maintenances(where:{tenant_id:{_eq:"${tenantId}"}}){
            start_date
            end_date
            room{
                name
                room_type{
                    name
                }
            }
        }
    }`

    const rooms = (await gqlPostQueryResult(roomQuery)).data.rooms

    const roomTypes = (await gqlPostQueryResult(roomTypeQuery)).data.room_types

    const accommodations = (await gqlPostQueryResult(accommodationQuery)).data.accommodations
    const maintenances = (await gqlPostQueryResult(maintenanceQuery)).data.maintenances

    let reports = []
    if (rooms.length === 0)
        return reports;

    for (let offset = 0; offset <= (toDate.diff(fromDate, 'day')); offset++) {
        const date = moment(fromDate).add(offset, 'day')
        reports.push({
            date: date.format("YYYY-MM-DD"),
            data: rooms.map(room => {
                return {
                    room: room.name,
                    room_type: room.room_type.name,
                    maintenance: maintenances.filter(m => room.name == m.room.name &&
                        (!m.start_date || date.isSameOrAfter(moment(m.start_date), 'day')) &&
                        (!m.end_date || date.isSameOrBefore(moment(m.end_date), 'day'))).length > 0
                }
            })
        })

    }

    return reports.map(record => ({
        date: record.date,
        ...roomTypes.reduce((acc, roomType) => ({
            ...acc, [roomType.name]: {
                available: record.data.filter(d => !d.maintenance && d.room_type == roomType.name).length,
                occupied: accommodations.filter(acc => acc.room_type.name == roomType.name && moment(record.date).isSameOrAfter(moment(acc.check_in_date), 'day') && moment(record.date).isSameOrBefore(moment(acc.check_out_date))).length
            }
        }), {})
    }))

}


